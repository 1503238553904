html {
	position: relative;
	min-height: 100%;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	padding: 0;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: 400;
	/* background-color: #f6f6f6; */
	/* color: #5e5e5e; */
	background-color: black;
	color: white;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
	color: #a0a0a0;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 17px;
}

p {
	font-size: 17px;
}

a {
	color: white;
}

.TENetworkActivity .TEPopupContent {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
}

/*
*
*
* REACT-TABLE-STYLES
*
*/

.ReactTable {
	border-radius: 5px;
	overflow: hidden;
	text-align: center;
}

.ReactTable .rt-thead.-header,
.ReactTable .rt-thead.-headerGroups {
	/* background-color: #e3006a; */
	background-color: black;
}

.ReactTable .rt-thead.-header .rt-th,
.ReactTable .rt-thead.-headerGroups .rt-th {
	color: white;
	padding: 10px 5px;
	font-weight: 500;
	font-size: 14px;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.ReactTable .rt-thead.-header .rt-th:last-child,
.ReactTable .rt-thead.-headerGroups .rt-th:last-child {
	border-right: none;
}

.ReactTable .rt-table .rt-tbody .a {
	color: black;
}

@media (max-width: 1000px) {

	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 13px;
		padding: 8px 5px;
	}
}

@media (max-width: 800px) {

	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 12px;
	}
}

/* @media (max-width: 650px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		padding: 5px 5px;
	}
} */

.ReactTable .rt-thead.-filter .rt-th {
	color: white;
	padding: 10px 5px;
}

@media (max-width: 800px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 8px 5px;
	}
}

@media (max-width: 650px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 5px;
	}
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	-webkit-box-shadow: inset 0 3px 0 0 white;
	box-shadow: inset 0 3px 0 0 white;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	-webkit-box-shadow: inset 0 -3px 0 0 white;
	box-shadow: inset 0 -3px 0 0 white;
}

.ReactTable .rt-thead .rt-th input[type='text'] {
	background-image: url(./images/searchIcon.png);
	background-position: calc(100% - 3px) center;
	background-repeat: no-repeat;
	background-size: auto calc(100% - 6px);
	padding: 5px;
	border: 1px solid #e5e5e5;
	border-radius: 0px;
	font-size: 16px;

	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}

@media (max-width: 1000px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}

@media (max-width: 800px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}

@media (max-width: 650px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th input[type='text']:hover {
	border-color: #e3006a;
	box-shadow: 0 0 0 1px #e3006a inset;
}

.ReactTable .rt-tbody .rt-td {
	align-self: center;
	padding: 10px 5px;
	font-size: 14px;
	min-height: 30px;
}

.ReactTable .rt-tr.-odd {
	background-color: #4e4e4e;
}

.ReactTable .rt-tr,
.pagination-bottom {
	background-color: #3f3f3f;
}

.react-datepicker__header {
	background-color: #3f3f3f;
}

.react-datepicker {
	background-color: #4e4e4e;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker__day {
	color: white;
}

.ReactTable .-pagination .-previous button,
.ReactTable .-pagination .-next button {
	color: black;
	background-color: white;
}

@media (max-width: 800px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 8px 5px;
		font-size: 13px;
	}
}

@media (max-width: 650px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 5px;
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th.right {
	text-align: right;
}

.ReactTable .rt-tbody .rt-td.actionCell {
	padding: 5px;
}

.ReactTable .rt-tbody .rt-td.noPadding {
	padding: 0px;
}

.ReactTable .rt-tbody .rt-td.center {
	text-align: center;
}

.ReactTable .rt-tbody .rt-td.capitalize {
	text-transform: capitalize;
}

.ReactTable .rt-tbody .rt-td.center-capitalize {
	text-align: center;
	text-transform: capitalize;
}

.ReactTable .rt-tbody .rt-td.right {
	text-align: right;
}

/* React Big Calendar*/
/* .rbc-calendar {
	height: 800px !important;
	margin-bottom: 50px;
} */

/*
*
* SHITTY RITTAL STYLE OVERRIDE
*
*/
.TEInputRowTitle,
.TESearchSelectRowTitle,
.TEDatetimeRowTitle,
.TEFileManagerRowTitle,
.TESegmentedGroupTitle,
.TECheckboxGroupTitle,
.TECheckboxGroupLabel {
	color: white !important;
}

.TEPopupContent .TEInputRowTitle {
	color: black !important;
}

.TEPopupContent {
	border-radius: 0;
}

.TEPopupContent .TEPopupFormButton {
	color: black !important;
	border-radius: 0;
}

.TEPopupContent .TEPopupFormButton:hover {
	color: white !important;
}

.TEMultiStepFormButtonContainer .TEMultiStepFormStepButton {
	border-radius: 0;
	color: black;
}

.TESegmentedGroupSegmentedLabelWrapper .TESegmentedGroupSegmentedLabel {
	border-radius: 0;
	color: black;
}

.TEFileManagerFileName {
	color: black !important;
}

.TESubNavbarLink {
	color: white !important;
}

.TESubNavbarContent {
	border-top: none !important;
}

.TEPanelTitleBar {
	background-color: black !important;
	border-bottom: 1px solid #797a7c !important;
}

.TEPopupContent,
.TEAlertButton {
	border-radius: 0 !important;
	color: black !important;
}

.TEConfirmTitle,
.TEConfirmMessage,
.TEConfirmButton {
	border-radius: 0 !important;
	color: black !important;
}

.rbc-toolbar button {
	color: white !important;
	border-radius: 0 !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
	background-color: #c5c5c5 !important;
}

.rbc-day-bg {
	background-color: #3f3f3f;
}

.rbc-time-view {
	background-color: #3f3f3f;
}

.rbc-agenda-content {
	background-color: #3f3f3f;
}

.rbc-day-bg.rbc-today {
	background-color: #8b8c8d;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today,
.rbc-header.rbc-today {
	background-color: #8b8c8d;
}

.rbc-day-bg.rbc-off-range-bg {
	background-color: #6e6c6c;
}

.rbc-date-cell.rbc-off-range {
	color: white;
}

.rbc-event-content,
.rbc-event.rbc-event-allday {
	background-color: #e3006a;
	border-radius: 0;
}

.rbc-current-time-indicator {
	background-color: #e3006a !important;
}

/* .TEPopupContent {
	background-color: black !important;
}
.TEPopupFormButton,
.TEAlertButton {
	background-color: black !important;
	color: white !important;
}
.TEAlertTitle,
.TEAlertMessage {
	color: white !important;
} */
.PowerSelect,
.PowerSelect__Option,
.PowerSelect__Trigger,
.PowerSelect__TriggerLabel,
.TESearchSelectRowInput {
	color: black;
	border-radius: 0px !important;
}

.PowerSelect .PowerSelect__TriggerLabel {
	width: 90%;
}

.TEErrorLoadingAlertTitle {
	color: black;
}

.TEInputRowInput,
.TEFileInputInputWrapper,
.TEFileInputClearButton,
.TEDatetimeRowDateInput {
	border-radius: 0px !important;
	color: black;
}